import React from 'react'

import Container from './container'
import * as styles from './footer.module.css'

const Footer = () => (
  <Container as="footer">
    <div className={styles.container}>
      Blog headless desarrollado con <a href="https://contentful.com/" target="_blank">Contentful</a> y{' '}
      <a href="https://gatsbyjs.com" target="_blank">Gatsby</a>{' '}
    </div>
    <div className='footer'>
      <a href='https://www.bekome.digital' target="_blank">Expertos Ecommerce Barcelona</a>
    </div>
  </Container>
)

export default Footer
