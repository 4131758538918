import React from 'react'
import { Link } from 'gatsby'

const Navigation = () => (
  <nav role="navigation" className="container" aria-label="Main">
    <Link to="/" className="logoLink">
      <span className="logo" />
      <span className="navigationItem">Manel Lavela · Experto Ecommerce Magento</span>
    </Link>
    <ul className="navigation">
    <div className="dropdown">
      <button className="dropbtn">
        <Link to="/servicios/" activeClassName="active">
          Servicios
        </Link>
      </button>
      <div className="dropdown-content">
        <Link to="/consultoria-ecommerce/" activeClassName="active">
          Consultoría ecommerce
        </Link>
        <Link to="/desarrollo-magento-commerce/" activeClassName="active">
          Desarrollo ecommerce
        </Link>
        <Link to="/desarrollo-bigcommerce/" activeClassName="active">
          Soporte y mantenimiento
        </Link>
        <Link to="/desarrollo-bigcommerce/" activeClassName="active">
          Integraciones ERP
        </Link>
      </div>
    </div>
      <li className="navigationItem">
        <Link to="/experto-magento-commerce/" activeClassName="active">
          Experto Magento
        </Link>
      </li>
      <li className="navigationItem">
        <Link to="/experto-bigcommerce/" activeClassName="active">
          Experto BigCommerce
        </Link>
      </li>
      <li className="navigationItem">
        <Link to="/quien-soy/" activeClassName="active">
          Quién soy
        </Link>
      </li>
    </ul>
  </nav>
)

export default Navigation
